@charset "UTF-8";

:root {
    --color-main: #144683;
    --color-font: #000;
    --color-font-invert: #fff;
    --color-border: var(--color-main);

    --bg-color: #fff;

    --font-sans-serif: Arial, sans-serif;
    --font-serif: "Times New Roman", Times, serif;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    height: 100%;
    width: 100%;
    font-size: 62.5%; /* rem-trick, 1rem = 10px */
    margin: 0;
    padding: 0;
}

body {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    width: 100%;
    height: 100%;
    font-family: var(--font-sans-serif);
    font-size: 1.6rem;
    color: var(--color-font);

    word-break: break-word;
    hyphens: auto;
}

body {
    background-color: var(--bg-color);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-serif);
    font-weight: normal;
}

h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 3.2rem; /* 32px */
}

h3 {
    font-size: 1.8rem;
}

h4, h5, h6 {
    font-size: 2.4rem;
}

a {
    color: var(--color-font);
}

p {
    font-family: var(--font-sans-serif);
    font-size: 1.4rem;
    line-height: 1.3;
}

img {
    max-width: 100%;
    height: auto;
}

main {
    padding: 10px;
    min-height: 100%;
    width: 100%;
    background-color: var(--bg-color);
}

/* Formularelemente */
input, textarea, select, button {

}

button, input[type=submit], input[type=button], input[type=reset] {
    cursor: pointer;
}

label {
    display: block;
    cursor: pointer;
}

/* Klassen - BEM: https://codeburst.io/understanding-css-bem-naming-convention-a8cca116d252 */
.clearfix::before,
.clearfix::after {
    content: " ";
    display: table;
}

.clearfix::after {
    clear: both;
}

.clearfix {
    *zoom: 1; /* Für den IE6 und IE7 */
}

.content {
    padding-bottom: 20px;
}

.content h1 {
    text-align: center;
}

.content__header {
    font-family: var(--font-serif);
    font-style: italic;
    font-size: 1.4rem;
    margin: 0 0 6px 0;
}

.content__header-logo {
    width: 73px;
    height: 24px;
}

.content__header-title {
    font-size: 1.8rem;
    line-height: 1.45;
    letter-spacing: -0.5px;
    display: inline-block;
    vertical-align: top;
    margin-left: 2px;
}

.content__footer {
    font-size: 1.1rem;
    padding-top: 3px;
    border-top: 1px solid var(--color-border);
}

.content__footer a {
    text-decoration: none;
    margin-right: 9px;
}

/* custom checkbox */
input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label::before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    float: left;
    border: 1px solid var(--color-border);
    background-color: var(--bg-color);
    margin-right: 5px;
    position: relative;
    z-index: 2;
}

/* aktive Checkbox */
input[type="checkbox"]:checked + label::before {
    background: url(../img/icn_checked.gif) no-repeat 0 0;
}

.hersteller__box input[type="checkbox"]:checked + label::before {
    border: 1px solid var(--bg-color);
}

.hersteller__box:hover .hersteller__box-details,
input[type="checkbox"]:checked + label .hersteller__box-details {
    display: block;
}

.hersteller__box:hover .hersteller__label,
input[type="checkbox"]:checked + .hersteller__label {
    background-color: var(--color-main);
}

.hersteller__box:hover .hersteller__label .hersteller__box-logo,
input[type="checkbox"]:checked + .hersteller__label .hersteller__box-logo {
    display: none;
}

.hersteller-alle__label {
    line-height: 1.4;
}

.hersteller {
    margin-bottom: 10px;
}

.hersteller__box {
    float: left;
    width: calc(50% - 5px);
    height: 90px;
    border: 1px solid var(--color-border);
    position: relative;
    overflow: hidden;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-right: 10px;
    margin-top: 10px;
}

.hersteller__box:nth-child(2n) {
    margin-right: 0;
}

.hersteller__label {
    background-color: transparent;
    padding: 5px;
    position: relative;
    height: 100%;
}

.hersteller__box-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.hersteller__box-details {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 25px;
    color: var(--color-font-invert);
}

.hersteller__box-details strong {
    display: inline-block;
    margin-bottom: 5px;
}

.hersteller-alle {
    margin: 10px 0 0 5px;
    font-size: 1.2rem;
}

.fieldset {
    position: relative;
    margin: 20px 0 15px;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: var(--color-font-invert);
    background: var(--color-main) linear-gradient(90deg, var(--color-main) 0%, rgba(230, 230, 230, .35) 33%, var(--color-main) 100%);
}

.fieldset__legend {
    font-size: 1.4rem;
    line-height: 1.3;
    padding: 8px 0 12px;
}

.fieldset__legend.fieldset__legend--small {
    font-size: 1.2rem;
    padding: 12px 0;
}

.fieldset p {
    font-size: 1.2rem;
}

.fieldset a {
    color: var(--color-font-invert);
}

.fieldset input[type=submit] {
    width: 115px;
    float: right;
    margin-top: 14px;
}

.fieldset input, .fieldset select, .fieldset textarea {
    width: 100%;
    height: 20px;
    color: var(--color-font);
    background-color: var(--color-font-invert);
    border: 1px solid var(--color-font-invert);
}

.fieldset textarea {
    height: 90px;
}

.fieldset__item {
    margin: 8px 0 10px 0;
}

.fieldset__col-30 {
    width: 30%;
    margin-right: 10px;
}

.fieldset__col-70 {
    width: calc(70% - 10px);
}

.fieldset__item--cols {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.fieldset__footer {
    margin: 20px 0 0 0;
}

.fieldset__footer small {
    font-size: 1rem;
}

.fieldset__container {
    visibility: hidden;
}

.fieldset.-stage-1 .fieldset__container.-stage-1,
.fieldset.-stage-2 .fieldset__container.-stage-2 {
    visibility: visible;
}

/* Weiterempfehlung - Vielen Dank */
.fieldset__container.-submitted {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.fieldset__container p {
    font-size: 2rem;
    text-align: center;
}

.form__button.form__button,
.error__close.error__close {
    cursor: pointer;
    background-color: var(--bg-color);
    color: var(--color-font);
    border: 1px solid var(--color-font);
    text-decoration: none;
    padding: 3px 50px;
    display: inline-block;
    margin-top: 6px;
}

.error {
    background-color: rgba(255, 255, 255, .7);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.error.-active {
    display: flex;
}

.error__body {
    background-color: var(--color-main);
    color: var(--color-font-invert);
    padding: 40px;

    display: flex;
    flex-flow: column wrap;
}

.error__close {
    align-self: center;
}

/* Desktop */
@media (min-width: 900px) {
    body {
        background: url("../img/mis_bg.png") no-repeat fixed;
    }

    h1 {
        font-size: 4.8rem;
    }

    h2 {
        font-size: 6.5rem; /* 65px */
    }

    h3 {
        font-size: 4.2rem;
    }

    p {
        font-family: var(--font-sans-serif);
        font-size: 2rem;
        line-height: 1.3;
        text-align: left;
    }

    main {
        padding: 0;
        margin: 20px auto;
        max-width: 900px;
        box-shadow: 0 0 6px #afafaf;
    }

    /* Klassen */
    .content {
        padding: 20px 30px;
    }

    .content h1 {
        margin-bottom: 25px;
    }

    .content__header {
        margin: 0 0 16px 0;
    }

    .content__header-logo {
        width: auto;
        height: auto;
    }

    .content__header-title {
        font-size: 3.6rem;
        margin-left: 9px;
    }

    .content__hero {
        margin-bottom: 4px;
        border: 1px solid var(--color-border);
    }

    .content__footer {
        font-size: 1.2rem;
    }

    .hersteller-form {
        margin: 35px 0 0 0;
    }

    .hersteller-alle {
        margin: 10px 0 20px 11px;
    }

    input[type="checkbox"] + label::before {
        margin-right: 10px;
    }

    .hersteller {
        margin-bottom: 20px;
    }

    .hersteller__box {
        width: 256px;
        height: 128px;
        font-size: 1.2rem;

        margin-right: 36px;
        margin-top: 36px;
    }

    .hersteller__box:nth-child(-n+3) {
        margin-top: 0;
    }

    .hersteller__box:nth-child(2n) {
        margin-right: 36px;
    }

    .hersteller__box:nth-child(3n) {
        margin-right: 0;
    }

    .hersteller__label {
        padding: 10px;
    }

    .hersteller__box-details {
        padding: 8px 10px 10px 35px;
    }

    .fieldset {
        margin: 20px 0 20px;
        padding: 20px 48px 30px;
    }

    .fieldset__item {
        margin: 8px 0 0 0;
    }

    .fieldset__item label {
        margin-bottom: 2px;
    }

    .fieldset__col-3 {
        width: 206px;
        margin-right: 61px;
        float: left;
    }

    .fieldset__col-3:last-child {
        margin-right: 0;
    }

    .fieldset__col-3--bottom {
        margin-top: 54px;
    }

    .fieldset__footer small {
        font-size: 1.2rem;
    }

    .fieldset textarea {
        height: 120px;
    }

    .fieldset__legend {
        font-size: 2rem;
    }

    .fieldset__container p {
        font-size: 2rem;
        text-align: center;
        padding: 0 20%;
    }

}
